<template>
  <section class="page_401">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="col-sm-10 col-sm-offset-1 text-center">
            <div class="four_zero_one_bg">
              <!-- <h1 class="text-center">401</h1> -->
            </div>

            <div class="contant_box_401 mt-2">
              <h3 class="h2">Parece que você esta acessando uma landing page que você não tem acesso.</h3>

              <p>Caso esse erro persista limpe seus cookies</p>

              <a href="https://app.cubosuite.com.br/landingpages" class="link_401">Ir para o cubo</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStorage } from '@vueuse/core'

const token = useStorage('pagebuilder-token', '')

token.value = null
</script>

<style>
.page_401 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_401 img {
  width: 100%;
}

.four_zero_one_bg {
  /* background-image: url(../assets/images/401.png); */
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
}

.four_zero_one_bg h1 {
  font-size: 80px;
  color: #032e3f
}

.four_zero_one_bg h3 {
  font-size: 80px;
}

.link_401 {
  color: #fff !important;
  padding: 10px 20px;
  background: #032e3f;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_401 {
  margin-top: -50px;
}
</style>
