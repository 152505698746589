<template>
  <Loading :active="saving" :can-cancel="false" :is-full-page="true">
    <template #default>
      <div class="spinner spinner--cube-grid" style="width: 60px; height: 60px">
        <div class="sk-cube sk-cube1" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube2" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube3" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube4" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube5" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube6" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube7" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube8" style="background-color: #032e3f; animation-duration: 1.3s"></div>
        <div class="sk-cube sk-cube9" style="background-color: #032e3f; animation-duration: 1.3s"></div>
      </div>
    </template>
  </Loading>

  <div class="pgbuilder">
    <div class="top-bar" id="top-bar" ref="topBar" :class="{ 'display-none': previewMode }">
      <ul class="menu-builder">
        <li class="menu-item"> <img src="../assets/images/svg/logo.svg"> <i class="bi bi-chevron-down icon-drop"></i>
          <div class="div-sub">
            <ul class='sub-menu'>
              <li class="sub-menu-item">
                <a href="https://app.cubosuite.com.br/landingpages" target="_blank">Voltar para o dashboard</a>
              </li>
              <li class="li-divider"></li>
              <!--<li class="sub-menu-item">
                <a>Histórico de versões do projeto</a>
              </li>-->
              <!--<li class="sub-menu-item drop-down">
                <a>Arquivo</a>
                <i class="bi bi-chevron-right icon-sub-drop"></i>
                <div>
                  <ul>
                    <li> <a>Novo</a></li>
                    <li> <a>Nova página</a></li>
                    <li class="li-divider"></li>
                    <li> <a>Duplicar projeto</a></li>
                  </ul>
                </div>
              </li>-->
              <li class="sub-menu-item drop-down">
                <a> Ajuda</a>
                <div>
                  <ul>
                    <li><a>Tuturial do Pagebuilder</a></li>
                    <li> <a href="https://cubosuite.ladesk.com/973906-Primeiros-Passos" target="_blank">Solicitar
                        ajuda</a></li>
                    <li><a>Reportar erro</a></li>
                  </ul>
                </div>
              </li>
              <li class="li-divider"></li>
              <li class="sub-menu-item"><a href="https://app.cubosuite.com.br/settings/user/profile" target="_blank">Sua
                  conta</a></li>
            </ul>
          </div>

        </li>
      </ul>
      <div class="options-top">
        <div class='option-button' ref="toolsDiv"><i class="bi bi-gear-wide"></i> Ferramentas
          <input type="checkbox" id="btn-tools" name="btn-tools" class="btn-menu" ref="toolsButton"
            @click="toggleMenu('tools', $event)" />
          <div class="top-tools" ref="toolsBar"></div>
        </div>
        <div class='option-button' style="opacity: 0;" ref="commentDiv"><i class="bi bi-chat-text-fill"></i> Comentários
          <input type="checkbox" id="btn-comment" name="btn-comment" class="btn-menu" ref="commentButton"
            @click="toggleMenu('comment', $event)" />
          <div class="top-comment" ref="commentBar"></div>
        </div>
      </div>
      <div class="devices-contet">
        <span>Resolução de tela:</span>
        <div class="devices"></div>
      </div>
      <div class="options-tool" id="option-menu">
      </div>
    </div>
    <div class="side-bar" id="side-bar" ref="sideBar" :class="{ 'display-none': previewMode }">
      <div class="sider-bar-menu">
        <div class="options-button" id="components-button" title="Blocos Prontos" ref="blockButton">
          <i class="bi bi-boxes"></i>
          <input type="checkbox" id="btn-block" name="btn-block" class="btn-menu" ref="checkBlock"
            @click="toggleMenu('blocks', $event)" />
        </div>
        <div class="options-button" id="layout-button" title="Layout" ref="layoutButton">
          <i class="bi bi-grid-1x2"></i>
          <input type="checkbox" id="btn-layout" name="btn-layout" class="btn-menu" ref="checkLayout"
            @click="toggleMenu('layout', $event)" />
        </div>
        <div class="options-button" id="titles-button" title="Títulos" ref="titlesButton">
          <i class="bi bi-fonts"></i>
          <input type="checkbox" id="btn-titles" name="btn-titles" class="btn-menu" ref="checkTitles"
            @click="toggleMenu('titles', $event)" />
        </div>
        <div class="options-button" id="text-button" title="parágrafos" ref="textButton">
          <i class="bi bi-paragraph"></i>
          <input type="checkbox" id="btn-text" name="btn-text" class="btn-menu" ref="checkText"
            @click="toggleMenu('text', $event)" />
        </div>
        <div class="options-button" id="components-button" title="Botões" ref="buttonButton">
          <i class="bi bi-usb-fill"></i>
          <input type="checkbox" id="btn-extra" name="btn-extra" class="btn-menu" ref="checkButton"
            @click="toggleMenu('button', $event)" />
        </div>
        <div class="options-button" id="components-button" title="Imagens" ref="imagesButton">
          <i class="bi bi-images"></i>
          <input type="checkbox" id="btn-extra" name="btn-extra" class="btn-menu" ref="checkImages"
            @click="toggleMenu('image', $event)" />
        </div>
        <div class="options-button" id="components-button" title="Vídeos" ref="videosButton">
          <i class="bi bi-play-btn"></i>
          <input type="checkbox" id="btn-extra" name="btn-extra" class="btn-menu" ref="checkVideos"
            @click="toggleMenu('video', $event)" />
        </div>
        <div class="options-button" id="components-button" title="Formulários" ref="formsButton">
          <i class="bi bi-clipboard2"></i>
          <input type="checkbox" id="btn-extra" name="btn-extra" class="btn-menu" ref="checkForms"
            @click="toggleMenu('forms', $event)" />
        </div>
        <div class="options-button" id="components-button" title="Slideshows" ref="slidesButton">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.159 41.499">
            <g id="Grupo_1646" data-name="Grupo 1646" transform="translate(-570.546 -4601.501)">
              <g id="Grupo_1645" data-name="Grupo 1645" transform="translate(-77 -15)">
                <path id="Caminho_772" data-name="Caminho 772" d="M661.055,4650.838V4618h22.7v32.837Z" fill="none"
                  stroke-linejoin="round" stroke-width="3" />
                <path id="Caminho_773" data-name="Caminho 773" d="M683.384,4622.5h11.821v23.268H683.384" fill="none"
                  stroke-linejoin="round" stroke-width="3" />
                <path id="Caminho_774" data-name="Caminho 774" d="M661.055,4622.5H649.046v23.831h12.009" fill="none"
                  stroke-linejoin="round" stroke-width="3" />
                <g id="Elipse_30" data-name="Elipse 30" transform="translate(661 4654)" fill="none" stroke-width="1">
                  <circle cx="2" cy="2" r="2" stroke="none" />
                  <circle cx="2" cy="2" r="1.5" fill="none" />
                </g>
                <g id="Elipse_31" data-name="Elipse 31" transform="translate(670 4654)" fill="none" stroke-width="1">
                  <circle cx="2" cy="2" r="2" stroke="none" />
                  <circle cx="2" cy="2" r="1.5" fill="none" />
                </g>
                <g id="Elipse_32" data-name="Elipse 32" transform="translate(679 4654)" fill="none" stroke-width="1">
                  <circle cx="2" cy="2" r="2" stroke="none" />
                  <circle cx="2" cy="2" r="1.5" fill="none" />
                </g>
              </g>
            </g>
          </svg>
          <input type="checkbox" id="btn-extra" name="btn-extra" class="btn-menu" ref="checkSlides"
            @click="toggleMenu('slide', $event)" />
        </div>
        <div class="options-button" id="components-button" title="Mais elementos" ref="extraButton">
          <i class="bi bi-plus-lg"></i>
          <input type="checkbox" id="btn-extra" name="btn-extra" class="btn-menu" ref="checkExtra"
            @click="toggleMenu('extra', $event)" />
        </div>

        <div id="options-side">
          <div class="options-button" id="dark-button" title="Ativar modo escuro" title-ativ="Desativar modo escuro"
            ref="darkButton">
            <i class="bi bi-moon"></i>
            <input type="checkbox" id="btn-dark" name="btn-dark" class="btn-menu" ref="checkDark"
              @click="toggleDark()" />
          </div>
        </div>
      </div>

      <div style="overflow-y: hidden; max-height: 100%">
        <div class="side-bar-menu-content">
          <div class="options-content" id="blocks-titles" ref="blocksTitles">
            <div class="title-panel">Títulos</div>
            <div class="blocks-panel-btn" id="blocks-title-button" ref="blockTitleButton" data-close="Ocultar Títulos"
              style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-title" name="btn-panel-title" ref="checkPanelTitle" class="btn-menu"
                @click="toggleMenu('panelTitles', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content" id="blocks-preset" ref="blocksPreset">
            <div class="title-panel">Blocos Prontos</div>
            <div class="blocks-panel-btn" id="blocks-panel-button" ref="blockPanelButton" data-close="Ocultar blocos"
              style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-block" name="btn-panel-block" ref="checkPanelBlock" class="btn-menu"
                @click="toggleMenu('panelBlocks', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content" id="blocks-text" ref="blocksText">
            <div class="title-panel">Parágrafos</div>
            <div class="blocks-panel-btn" id="text-panel-button" ref="textPanelButton" data-close="Ocultar parágrafos"
              style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-text" name="btn-panel-text" ref="checkPanelText" class="btn-menu"
                @click="toggleMenu('panelText', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content" id="blocks-button" ref="blocksButton">
            <div class="title-panel">Botões</div>
            <div class="blocks-panel-btn" id="text-panel-button" ref="buttonPanelButton" data-close="Ocultar Botões"
              style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-text" name="btn-panel-text" ref="checkPanelButton" class="btn-menu"
                @click="toggleMenu('panelButton', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content" id="blocks-image" ref="blocksImage">
            <div class="title-panel">Imagens</div>
            <div class="blocks-panel-btn" id="text-panel-button" ref="imagePanelButton" data-close="Ocultar Imagens"
              style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-text" name="btn-panel-text" ref="checkPanelImage" class="btn-menu"
                @click="toggleMenu('panelImage', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content" id="blocks-video" ref="blocksVideos">
            <div class="title-panel">Vídeos</div>
            <div class="blocks-panel-btn" id="text-panel-button" ref="videosPanelButton" data-close="Ocultar Vídeos"
              style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-text" name="btn-panel-text" ref="checkPanelVideos" class="btn-menu"
                @click="toggleMenu('panelVideo', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content" id="blocks-form" ref="blocksForms">
            <div class="title-panel">Formulários</div>
            <div class="blocks-panel-btn" id="text-panel-button" ref="formsPanelButton" data-close="Ocultar Formulários"
              style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-text" name="btn-panel-text" ref="checkPanelForms" class="btn-menu"
                @click="toggleMenu('panelForms', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content" id="blocks-slide" ref="blocksSlides">
            <div class="title-panel">Slideshows</div>
            <div class="blocks-panel-btn" id="text-panel-button" ref="slidesPanelButton" data-close="Ocultar Slideshows"
              style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-text" name="btn-panel-text" ref="checkPanelSlides" class="btn-menu"
                @click="toggleMenu('panelSlides', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content" id="blocks-layout" ref="blocksLayout">
            <div class="title-panel">Layout</div>
            <div class="layout-panel-btn" id="layout-panel-button" ref="layoutPanelButton"
              data-close="Ocultar disposição" style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-layout" name="btn-panel-layout" ref="checkPanelLayout"
                class="btn-menu" @click="toggleMenu('panelLayout', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content" id="blocks-extra" ref="blocksExtra">
            <div class="title-panel">Elementos</div>
            <div class="layout-panel-btn" id="extra-panel-button" ref="ExtraPanelButton" data-close="Ocultar elementos"
              style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-panel-extra" name="btn-panel-extra" ref="checkPanelExtra" class="btn-menu"
                @click="toggleMenu('panelExtra', $event)" />
            </div>
            <!--<div class="filters">
                  <button class="btn-search"></button>
                  <input type="search" id="serchBlock" @keyup="filterBlocks($event)" placeholder="Proucurar blocos..."
                    title="Procurar" />
                </div> -->
            <div class="divider"></div>
          </div>
          <div class="options-content open-layer" id="layers" ref="layers">
            <div class="title-panel">Camadas</div>
            <div class="divider"></div>
            <div class="layer-content" ref="layerContent">
            </div>
            <div class="layer-btn" id="layers-button" ref="layerButton" title="Camadas" data-close="Ocultar camadas"
              data-open="Expandir camadas" style="font-size: 23px;">
              <img src="../assets/images/closetag.png">
              <input type="checkbox" id="btn-layer" name="btn-layer" ref="checklayer" class="btn-menu"
                @click="toggleMenu('layers', $event)" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="gjs" class="w-complete" ref="builder" :class="{ 'inpreview': previewMode }"></div>

    <div class="side-bar" id="side-bar-style" ref="sideBar2" :class="{ 'display-none': previewMode }">

      <div style="overflow-y: hidden; max-height: 100%; width: 100%;">

        <div class="side-bar-menu-content">
          <div class="title-panel options-button active" id="style-button" ref="styleButton">Estilos da seleção
            <input type="checkbox" id="btn-style" name="btn-style" class="btn-menu" ref="checkStyle"
              @click="toggleMenu('styles', $event)" />
          </div>
          <div class="title-panel options-button" id="config-button" ref="configButton">Configurações da seleção
            <input type="checkbox" id="btn-config" name="btn-config" class="btn-menu" ref="checkConfig"
              @click="toggleMenu('configs', $event)" />
          </div>
          <div class="options-content display-block" id="style-manager" ref="styleManager">
            <div class="divider"></div>
            <div id="selector" ref="selectorManager"></div>
          </div>
          <div class="options-content" id="config-manager" ref="configManager">
            <div class="divider"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//Imports
import "grapesjs/dist/css/grapes.min.css";
import "../assets/css/page-builder.css";
import "grapesjs-plugin-toolbox/dist/grapesjs-plugin-toolbox.min.css";
import "grapesjs-component-code-editor/dist/grapesjs-component-code-editor.min.css";
import "grapesjs-rulers/dist/grapesjs-rulers.min.css";

import { useDark, useToggle } from '@vueuse/core'
import ptBR from "../locale";

import Loading from "vue-loading-overlay";
import { ofetch } from "ofetch";
import { ref, onMounted } from "vue";
import grapesjs from "grapesjs";
import { useFetch, useStorage } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";

import cuboBasics from "../plugins/cubo-basics";
import cuboBlocks from "../plugins/cubo-blocks";
import parserPostCSS from "grapesjs-parser-postcss";
import customCode from "grapesjs-custom-code";
import scriptEditor from "grapesjs-script-editor";
import borders from "grapesjs-plugin-borders";
// import toolbox from "grapesjs-plugin-toolbox";
import codeEditor from "grapesjs-component-code-editor";
import rulers from "grapesjs-rulers";
import typed from "grapesjs-typed";
import lory from "../plugins/lory";
import toolbar from "../plugins/toolbar";
import styleBg from "grapesjs-style-bg"
//import tailwind from "grapesjs-tailwind";
// import fonts from '@silexlabs/grapesjs-fonts';
// import saveDelay from '@silexlabs/grapesjs-storage-rate-limit'
// import input from "../plugins/input";

import cuboForm from "../plugins/cubosuite-form";

//Variables
const router = useRouter();
const token = useStorage("pagebuilder-token", "");
const route = useRoute();
const saving = ref(false);
const loaderText = ref("");
const saveType = ref("");
const url = ref("")
const domain = ref("")
const previewMode = ref(false);
const editor = ref(null);
const isDark = useDark()
const toggleDark = useToggle(isDark)

//Refs
const checkBlock = ref(null);
const blockButton = ref(null);
const blocks = ref(null);
const blocksPreset = ref(null);
const blockPanelButton = ref(null)
const checkPanelBlock = ref(null)
const blocksLayout = ref(null)
const layoutPanelButton = ref(null)
const checkPanelLayout = ref(null)
const layoutButton = ref(null)
const checkLayout = ref(null)
const checkPanelExtra = ref(null)
const ExtraPanelButton = ref(null)
const blocksExtra = ref(null)
const checkExtra = ref(null)
const extraButton = ref(null)
const checklayer = ref(null);
const layerButton = ref(null);
const layers = ref(null);
const checkConfig = ref(null);
const configButton = ref(null);
const configManager = ref(null);
const checkStyle = ref(null);
const styleButton = ref(null);
const styleManager = ref(null);
const selectorManager = ref(null);
const builder = ref(null)
const layerContent = ref(null)
const toolsBar = ref(null)
const toolsButton = ref(null)
const toolsDiv = ref(null)
const commentDiv = ref(null)
const commentBar = ref(null)
const commentButton = ref(null)
const darkButton = ref(null)
const checkDark = ref(null)
const topBar = ref(null)
const sideBar = ref(null)
const sideBar2 = ref(null)
const titlesButton = ref(null)
const checkTitles = ref(null)
const blocksTitles = ref(null)
const checkPanelTitle = ref(null)
const checkPanelText = ref(null)
const textPanelButton = ref(null)
const blocksText = ref(null)
const checkText = ref(null)
const textButton = ref(null)
const buttonButton = ref(null)
const buttonPanelButton = ref(null)
const checkButton = ref(null)
const checkPanelButton = ref(null)
const blocksButton = ref(null)
const checkPanelImage = ref(null)
const imagePanelButton = ref(null)
const blocksImage = ref(null)
const checkImages = ref(null)
const imagesButton = ref(null)
const videosPanelButton = ref(null)
const checkPanelVideos = ref(null)
const blocksVideos = ref(null)
const checkVideos = ref(null)
const videosButton = ref(null)
const checkPanelForms = ref(null)
const formsPanelButton = ref(null)
const blocksForms = ref(null)
const checkForms = ref(null)
const formsButton = ref(null)
const checkPanelSlides = ref(null)
const slidesPanelButton = ref(null)
const blocksSlides = ref(null)
const checkSlides = ref(null)
const slidesButton = ref(null)


if (!token.value || token.value.length === 0) {
  window.location.href = `${process.env.VUE_APP_URL_CUBO}/login?redirect=${process.env.VUE_APP_URL_BASE}/page/${route.params.id}&redirectType=pagebuilder`;
}

const pipes = await ofetch('https://api.cubosuite.com.br/pipes?limit=99999', {
  headers: {
    Authorization: `Bearer ${token.value}`,
  },
})

const forms = await ofetch('https://api.cubosuite.com.br/forms?limit=99999', {
  headers: {
    Authorization: `Bearer ${token.value}`,
  },
})

const customfields = await ofetch('https://api.cubosuite.com.br/customfields?limit=9999&status=active', {
  headers: {
    Authorization: `Bearer ${token.value}`,
  },
})


onMounted(async () => {
  editor.value = grapesjs.init({
    container: "#gjs",
    height: "calc(100vh - 71px)",
    width: "calc(100% - (3% + 16% + 30px) )",
    fonts: [{ name: 'ABeeZee', value: '"ABeeZee", sans-serif', variants: [] }],

    i18n: {
      localeFallback: "pt",
      messages: ptBR,
    },

    assetManager: {
      storageType: "",
      autosave: true,
      stepsBeforeSave: 1,
      storeOnChange: true,
      storeAfterUpload: true,
      multiUpload: true,
      embedAsBase64: true,
      uploadFile: async function (e) {
        loaderText.value = "Enviando arquivo";
        saving.value = true;
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;

        if (files.length > 0) {
          const formData = new FormData();
          formData.append("file", files[0], files[0].name);

          const upload = await useFetch(
            `${process.env.VUE_APP_URL_API}/files`,
            {
              headers: {
                Authorization: `Bearer ${token.value}`,
              },
            }
          )
            .post(formData)
            .json();

          editor.value.AssetManager.add(upload.data.value.link);
          saving.value = false;
          Swal.fire({
            text: "Arquivo enviado.",
            showConfirmButton: false,
            toast: true,
            position: "top-start",
            timer: "1000",
            timerProgressBar: true,
          });
        }
      },
      i18n: {
        locale: `pt`,
      },
    },

    canvas: {
      styles: [
        "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css",
        "https://fonts.googleapis.com/css?family=Abel|Abril+Fatface|Acme|Alegreya|Alegreya+Sans|Anton|Archivo|Archivo+Black|Archivo+Narrow|Arimo|Arvo|Asap|Asap+Condensed|Bitter|Bowlby+One+SC|Bree+Serif|Cabin|Cairo|Catamaran|Crete+Round|Crimson+Text|Cuprum|Dancing+Script|Dosis|Droid+Sans|Droid+Serif|EB+Garamond|Exo|Exo+2|Faustina|Fira+Sans|Fjalla+One|Francois+One|Gloria+Hallelujah|Hind|Inconsolata|Indie+Flower|Josefin+Sans|Julee|Karla|Lato|Libre+Baskerville|Libre+Franklin|Lobster|Lora|Mada|Manuale|Maven+Pro|Merriweather|Merriweather+Sans|Montserrat|Montserrat+Subrayada|Mukta+Vaani|Muli|Noto+Sans|Noto+Serif|Nunito|Open+Sans|Open+Sans+Condensed:300|Oswald|Oxygen|PT+Sans|PT+Sans+Caption|PT+Sans+Narrow|PT+Serif|Pacifico|Passion+One|Pathway+Gothic+One|Play|Playfair+Display|Poppins|Questrial|Quicksand|Raleway|Roboto|Roboto+Condensed|Roboto+Mono|Roboto+Slab|Ropa+Sans|Rubik|Saira|Saira+Condensed|Saira+Extra+Condensed|Saira+Semi+Condensed|Sedgwick+Ave|Sedgwick+Ave+Display|Shadows+Into+Light|Signika|Slabo+27px|Source+Code+Pro|Source+Sans+Pro|Spectral|Titillium+Web|Ubuntu|Ubuntu+Condensed|Varela+Round|Vollkorn|Work+Sans|Yanone+Kaffeesatz|Zilla+Slab|Zilla+Slab+Highlight",
        "https://fonts.googleapis.com",
        "https://fonts.gstatic.com",
        "https://fonts.googleapis.com/css2?family=Alex+Brush&family=Dancing+Script:wght@400..700&family=Fredoka:wght@300..700&family=Great+Vibes&family=Inter:wght@100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap"
      ],
      scripts: [
      ],
    },

    storageManager: {
      id: "",
      type: "cubo",
      autosave: false,
      stepsBeforeSave: 1,
      autoload: true,
      onStore: (data, editor) => {
        saving.value = true;
        const pagesHtml = editor.Pages.getAll().map((page) => {
          const component = page.getMainComponent();
          return {
            html: editor.getHtml({ component }),
            css: editor.getCss({ component }),
          };
        });
        return { data, pagesHtml };
      },
    },

    plugins: [
      toolbar,
      parserPostCSS,
      customCode,
      scriptEditor,
      borders,
      // toolbox,
      codeEditor,
      rulers,
      typed,
      lory,
      styleBg,
      //tailwind,   
      // fonts,
      // saveDelay,

      cuboBasics,
      cuboBlocks,
      cuboForm,
    ],

    pluginsOpts: {
      [borders]: {
        selected: "#0FA7FF",
        resizer: "#0FA7FF",
        toolbar: "none",
        badge: "#0FA7FF",
        highlighter: "#0FA7FF",
      },
      // [toolbox]: {
      //   panels: true,
      //   traitsInSm: false,
      //   breadcrumbs: true,
      //   gridBlock: false,
      // },
      [cuboForm]: {
        token: token.value,
        pipes: pipes.data,
        forms: forms.data,
        customfields: customfields.data,
      },
      // [fonts]: {
      //   api_key: process.env.VUE_APP_GOOGLE_FONTS_API
      // }
    },

    deviceManager: {
      devices: [
        {
          id: "desktop",
          name: "Desktop",
          width: "",
        },
        {
          id: "notebook",
          name: "Notebook",
          width: "98%",
          widthMedia: "1366px",
        },
        {
          id: "tablet",
          name: "Tablet",
          width: "768px",
          widthMedia: "992px",
        },
        {
          id: "mobilePortrait",
          name: "Mobile portrait",
          width: "320px",
          widthMedia: "575px",
        },
      ],
    },

    blockManager: {
      appendTo: blocks.value,
    },

    styleManager: {
      appendTo: styleManager.value,
      sectors: [
        {
          name: 'Transformar',
          open: false,
          properties: [
            {
              type: 'number',
              label: 'Largura:',
              property: 'width',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'number',
              label: 'Altura:',
              property: 'height',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'number',
              label: 'Largura máxima:',
              property: 'max-width',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'number',
              label: 'Largura mínima:',
              property: 'min-width',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'number',
              label: 'Altura máxima:',
              property: 'max-height',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'number',
              label: 'Altura mínima:',
              property: 'min-height',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'number',
              label: 'Rotação:',
              property: 'rotate',
              default: '0',
              units: ['deg'],
              min: '0',
            },
            {
              type: 'number',
              label: 'Posição na tela:',
              property: 'z-index',
              default: '0',
              min: '0',
            },
            {
              type: 'select',
              property: 'object-fit',
              label: 'Adaptação do objeto:',
              default: '',
              options: [
                { id: 'normal', label: 'Normal' },
                { id: 'cover', label: 'Preencher' },
                { id: 'contain', label: 'Conter' },
                { id: 'scale-down', label: 'Diminuir em escala' }
              ]
            },
          ],
        },
        {
          name: 'Espaçamentos',
          open: false,
          properties: [
            {
              type: 'composite',
              property: 'margin',
              label: 'Margem:',
              properties: [
                { type: 'number', units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'], default: '0', property: 'margin-top' },
                { type: 'number', units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'], default: '0', property: 'margin-right' },
                { type: 'number', units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'], default: '0', property: 'margin-bottom' },
                { type: 'number', units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'], default: '0', property: 'margin-left' },
              ]
            },
            {
              type: 'composite',
              property: 'padding',
              label: 'Espaçamento interno:',
              properties: [
                { type: 'number', units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'], default: '0', property: 'padding-top' },
                { type: 'number', units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'], default: '0', property: 'padding-right' },
                { type: 'number', units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'], default: '0', property: 'padding-bottom' },
                { type: 'number', units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'], default: '0', property: 'padding-left' },
              ]
            }
          ]
        },
        {
          name: 'Posição',
          open: false,
          properties: [
            {
              type: 'select',
              property: 'position',
              label: 'Tipo',
              default: 'static',
              options: [
                { id: 'static', label: 'Estático' },
                { id: 'relative', label: 'Relativo' },
                { id: 'absolute', label: 'Absolute' },
                { id: 'fixed', label: 'Fixo' }
              ]
            },
            {
              type: 'number',
              label: 'T:',
              property: 'top',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
            },
            {
              type: 'number',
              label: 'R:',
              property: 'right',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
            },
            {
              type: 'number',
              label: 'R:',
              property: 'bottom',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
            },
            {
              type: 'number',
              label: 'R:',
              property: 'left',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
            },

          ]
        },
        {
          name: 'Layout',
          open: false,
          properties: [
            {
              type: 'select',
              property: 'display',
              label: 'Tipo:',
              default: '',
              options: [
                { id: '', label: 'Selecione:' },
                { id: 'block', label: 'Bloco' },
                { id: 'flex', label: 'Flex' },
                { id: 'inline', label: 'Em linha' },
                { id: 'inline-block', label: 'Bloco em linha' },
                { id: 'none', label: 'Nenhum' }
              ]
            },
            {
              type: 'select',
              property: 'flex-direction',
              label: 'Direção:',
              default: '',
              options: [
                { id: '', label: 'Selecione:' },
                { id: 'row', label: 'Linha' },
                { id: 'column', label: 'Coluna' },
                { id: 'row-reverse', label: 'Linha reversa' },
                { id: 'column-reverse', label: 'Coluna reversa' },
              ]
            },
            {
              type: 'radio',
              property: 'align-items',
              label: 'Alinhar conteudo:',
              default: '',
              options: [
                { id: 'flex-start', label: 'Alinhado a Esquerda' },
                { id: 'center', label: 'Centralizado' },
                { id: 'flex-end', label: 'Alinhado a Direita' },
                { id: 'space-between', label: 'Entre espaços' },
                { id: 'space-around', label: 'Entre espaços' },
                { id: 'stretch', label: 'Entre espaços' },
              ]
            },
            {
              type: 'radio',
              property: 'justify-content',
              label: 'Alinhar conteudo  :',
              default: '',
              options: [
                { id: 'flex-start', label: 'Alinhado a Esquerda' },
                { id: 'center', label: 'Centralizado' },
                { id: 'flex-end', label: 'Alinhado a Direita' },
                { id: 'space-between', label: 'Entre espaços' },
                { id: 'space-around', label: 'Entre espaços' },
                { id: 'stretch', label: 'Entre espaços' },
              ]
            },
            {
              type: 'radio',
              property: 'flex-wrap',
              label: 'Quebrar',
              default: 'no-wrap',
              options: [
                { id: 'no-wrap', label: 'Não' },
                { id: 'wrap', label: 'Sim' },
              ]
            },
            {
              type: 'number',
              label: 'Brecha:',
              property: 'gap',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'select',
              property: 'overflow-x',
              label: 'Rolagem X:',
              default: '',
              options: [
                { id: '', label: 'Selecione: ' },
                { id: 'visible', label: 'Vísivel' },
                { id: 'hidden', label: 'Desativado' },
                { id: 'scroll', label: 'Barra de rolagem' },
                { id: 'auto', label: 'Automático' },
              ]
            },
            {
              type: 'select',
              property: 'overflow-y',
              label: 'Rolagem Y:',
              default: '',
              options: [
                { id: '', label: 'Selecione: ' },
                { id: 'visible', label: 'Vísivel' },
                { id: 'hidden', label: 'Desativado' },
                { id: 'scroll', label: 'Barra de rolagem' },
                { id: 'auto', label: 'Automático' },
              ]
            },

          ]
        },
        {
          name: 'typography',
          open: false,
          properties: [
            {
              type: 'select',
              property: 'font-family',
              label: 'Fonte:',
            },
            {
              type: 'number',
              label: 'Tamanho:',
              property: 'font-size',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'color',
              label: 'Cor:',
              default: 'black',
              property: 'color'
            },
            {
              type: 'select',
              property: 'font-weight',
              label: 'Peso:',
              default: '',
              options: [
                { id: '', label: 'Selecione: ' },
                { id: '100', label: 'Ultra Fina' },
                { id: '200', label: 'Extra Fina' },
                { id: '300', label: 'Fina' },
                { id: '400', label: 'Normal' },
                { id: '500', label: 'Medio' },
                { id: '600', label: 'Semi Negrito' },
                { id: '700', label: 'Negrito' },
                { id: '800', label: 'Extra Negrito' },
                { id: '900', label: 'Ultra Negrito' },
              ]
            },
            {
              type: 'radio',
              property: 'text-align',
              label: 'Alinhamento do texto:',
              default: '',
              options: [
                { id: 'left', label: 'esquerda' },
                { id: 'right', label: 'direita' },
                { id: 'center', label: 'center' },
                { id: 'justify', label: 'justificado' },
              ]
            },
            {
              type: 'number',
              label: 'Altura da linha:',
              property: 'line-height',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'number',
              label: 'Espaçamento:',
              property: 'letter-spacing',
              default: '0',
              units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
              min: '0',
            },
            {
              type: 'radio',
              property: 'text-decoration',
              label: 'Decoração:',
              default: '',
              options: [
                { id: 'none', label: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg>' },
                { id: 'underline', label: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-type-underline" viewBox="0 0 16 16"><path d="M5.313 3.136h-1.23V9.54c0 2.105 1.47 3.623 3.917 3.623s3.917-1.518 3.917-3.623V3.136h-1.23v6.323c0 1.49-.978 2.57-2.687 2.57s-2.687-1.08-2.687-2.57zM12.5 15h-9v-1h9z"/></svg>' },
                { id: 'line-through', label: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-type-strikethrough" viewBox="0 0 16 16"><path d="M6.333 5.686c0 .31.083.581.27.814H5.166a2.8 2.8 0 0 1-.099-.76c0-1.627 1.436-2.768 3.48-2.768 1.969 0 3.39 1.175 3.445 2.85h-1.23c-.11-1.08-.964-1.743-2.25-1.743-1.23 0-2.18.602-2.18 1.607zm2.194 7.478c-2.153 0-3.589-1.107-3.705-2.81h1.23c.144 1.06 1.129 1.703 2.544 1.703 1.34 0 2.31-.705 2.31-1.675 0-.827-.547-1.374-1.914-1.675L8.046 8.5H1v-1h14v1h-3.504c.468.437.675.994.675 1.697 0 1.826-1.436 2.967-3.644 2.967"/></svg>' },
              ]
            },

            {
              type: 'radio',
              property: 'font-style',
              label: 'Estilo:',
              default: '',
              options: [
                { id: 'normal', label: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg>' },
                { id: 'italic', label: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-type-italic" viewBox="0 0 16 16"><path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z"/></svg>' },
              ]
            },

            {
              type: 'radio',
              property: 'text-transform',
              label: 'Transformação:',
              default: '',
              options: [
                { id: 'none', label: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg>' },
                { id: 'uppercase', label: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alphabet-uppercase" viewBox="0 0 16 16"><path d="M1.226 10.88H0l2.056-6.26h1.42l2.047 6.26h-1.29l-.48-1.61H1.707l-.48 1.61ZM2.76 5.818h-.054l-.75 2.532H3.51zm3.217 5.062V4.62h2.56c1.09 0 1.808.582 1.808 1.54 0 .762-.444 1.22-1.05 1.372v.055c.736.074 1.365.587 1.365 1.528 0 1.119-.89 1.766-2.133 1.766zM7.18 5.55v1.675h.8c.812 0 1.171-.308 1.171-.853 0-.51-.328-.822-.898-.822zm0 2.537V9.95h.903c.951 0 1.342-.312 1.342-.909 0-.591-.382-.954-1.095-.954zm5.089-.711v.775c0 1.156.49 1.803 1.347 1.803.705 0 1.163-.454 1.212-1.096H16v.12C15.942 10.173 14.95 11 13.607 11c-1.648 0-2.573-1.073-2.573-2.849v-.78c0-1.775.934-2.871 2.573-2.871 1.347 0 2.34.849 2.393 2.087v.115h-1.172c-.05-.665-.516-1.156-1.212-1.156-.849 0-1.347.67-1.347 1.83"/></svg>' },
                { id: 'lowercase', label: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alphabet" viewBox="0 0 16 16"><path d="M2.204 11.078c.767 0 1.201-.356 1.406-.737h.059V11h1.216V7.519c0-1.314-.947-1.783-2.11-1.783C1.355 5.736.75 6.42.69 7.27h1.216c.064-.323.313-.552.84-.552s.864.249.864.771v.464H2.346C1.145 7.953.5 8.568.5 9.496c0 .977.693 1.582 1.704 1.582m.42-.947c-.44 0-.845-.235-.845-.718 0-.395.269-.684.84-.684h.991v.538c0 .503-.444.864-.986.864m5.593.937c1.216 0 1.948-.869 1.948-2.31v-.702c0-1.44-.727-2.305-1.929-2.305-.742 0-1.328.347-1.499.889h-.063V3.983h-1.29V11h1.27v-.791h.064c.21.532.776.86 1.499.86Zm-.43-1.025c-.66 0-1.113-.518-1.113-1.28V8.12c0-.825.42-1.343 1.098-1.343.684 0 1.075.518 1.075 1.416v.45c0 .888-.386 1.401-1.06 1.401Zm2.834-1.328c0 1.47.87 2.378 2.305 2.378 1.416 0 2.139-.777 2.158-1.763h-1.186c-.06.425-.313.732-.933.732-.66 0-1.05-.512-1.05-1.352v-.625c0-.81.371-1.328 1.045-1.328.635 0 .879.425.918.776h1.187c-.02-.986-.787-1.806-2.14-1.806-1.41 0-2.304.918-2.304 2.338z"/></svg>' },
                { id: 'capitalize', label: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-type" viewBox="0 0 16 16"><path d="m2.244 13.081.943-2.803H6.66l.944 2.803H8.86L5.54 3.75H4.322L1 13.081zm2.7-7.923L6.34 9.314H3.51l1.4-4.156zm9.146 7.027h.035v.896h1.128V8.125c0-1.51-1.114-2.345-2.646-2.345-1.736 0-2.59.916-2.666 2.174h1.108c.068-.718.595-1.19 1.517-1.19.971 0 1.518.52 1.518 1.464v.731H12.19c-1.647.007-2.522.8-2.522 2.058 0 1.319.957 2.18 2.345 2.18 1.06 0 1.716-.43 2.078-1.011zm-1.763.035c-.752 0-1.456-.397-1.456-1.244 0-.65.424-1.115 1.408-1.115h1.805v.834c0 .896-.752 1.525-1.757 1.525"/></svg>' },
              ]
            },
            {
              type: 'stack',
              property: 'text-shadow',
              label: 'Stack type',
              // Additional props
              properties: [
                { type: 'number', units: ['px'], default: '0', property: 'x' },
                { type: 'number', units: ['px'], default: '0', property: 'y' },
                { type: 'number', units: ['px'], default: '0', property: 'blur' },
                { type: 'color', default: 'black', property: 'color' },
              ]
            },

          ]
        },
        {
          name: 'Aparência',
          open: false,
          buildProps: [
            'background'
          ],
          properties: [

            {
              type: 'composite',
              property: 'border',
              label: 'Bordas: ',
              properties: [
                {
                  type: 'number',
                  property: 'border-width',
                  label: 'Espessura:',
                  default: '0',
                  units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
                  min: '0',
                },
                {
                  type: 'select',
                  property: 'border-style',
                  label: 'Estilo: ',
                  default: 'none',
                  options: [
                    { id: 'none', label: 'Nenhum' },
                    { id: 'solid', label: 'Sólido' },
                    { id: 'dotted', label: 'Pontilhada' },
                    { id: 'dashed', label: 'Tracejado' },
                    { id: 'double', label: 'dupla' },
                    { id: 'groove', label: 'entalhada' },
                    { id: 'ridge', label: 'elevada' },
                  ]
                },
                {
                  type: 'color',
                  label: 'Cor:',
                  default: 'black',
                  property: 'border-color'
                },
              ]
            },
            {
              type: 'composite',
              property: 'border-radius',
              label: 'Arredondamento: ',
              properties: [
                {
                  type: 'number',
                  property: 'border-top-left-radius',
                  label: 'Superior esquerda:',
                  default: '0',
                  units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
                  min: '0',
                },
                {
                  type: 'number',
                  property: 'border-top-right-radius',
                  label: 'Superior direita:',
                  default: '0',
                  units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
                  min: '0',
                },
                {
                  type: 'number',
                  property: 'border-bottom-left-radius',
                  label: 'Inferior esquerda:',
                  default: '0',
                  units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
                  min: '0',
                },
                {
                  type: 'number',
                  property: 'border-bottom-right-radius',
                  label: 'Inferior direita:',
                  default: '0',
                  units: ['px', '%', 'em', 'rem', 'vh', 'vw', 'auto'],
                  min: '0',
                },
              ]
            },
            {
              type: 'stack',
              property: 'box-shadow',
              label: 'Sombras: ',
              properties: [
                { type: 'number', units: ['px'], default: '0', property: 'h', label: 'X' },
                { type: 'number', units: ['px'], default: '0', property: 'v', label: 'Y' },
                { type: 'number', units: ['px'], default: '0', property: 'blur', label: 'Blur' },
                { type: 'number', units: ['px'], default: '0', property: 'pread', label: 'Aceleração' },
                { type: 'color', default: 'black', property: 'color', label: 'Cor' },
                {
                  type: 'select', default: '', property: 'type', label: 'Estilo',
                  options: [
                    { id: '', label: 'Exterior' },
                    { id: 'inset', label: 'Interior' },
                  ]
                },
              ]
            },
          ]
        },
        {
          name: 'Efeitos',
          open: false,
          buildProps: [
            'transform'
          ],
          properties: [
            {
              type: 'stack',
              property: 'transition',
              label: 'Transição: ',
              properties: [
                {
                  type: 'select', default: 'all', property: 'transition-property', label: 'Propriedade:',
                  options: [
                    { id: 'all', label: 'Todos' },
                    { id: 'height', label: 'Altura' },
                    { id: 'width', label: 'Largura' },
                    { id: 'background', label: 'Cor de fundo' },
                    { id: 'transform', label: 'Transformação' },
                    { id: 'box-shadow', label: 'Sombras' },
                    { id: 'opacity', label: 'Opacidade' },
                  ]
                },
                { type: 'number', units: ['s', 'ms'], default: '0', property: 'transition-duration', label: 'Duração' },
                {
                  type: 'select', default: 'linear', property: 'transition-timing-function', label: 'Tempo:',
                  options: [
                    { id: 'linear', label: 'Constante' },
                    { id: 'ease', label: 'Suave' },
                  ]
                },
                { type: 'number', units: ['s', 'ms'], default: '0', property: 'transition-delay', label: 'Atraso:' },
              ],
            },
            {
              type: 'slider',
              property: 'opacity',
              default: '100',
              label: 'Opacidade',
              units: ['%'],
            },
            {
              type: 'select',
              property: 'mix-blend-mode',
              default: 'normal',
              label: 'Mistura: ',
              options: [
                { id: 'normal', label: 'Normal' },
                { id: 'multiply', label: 'Multiplicar' },
                { id: 'screen', label: 'Tela' },
                { id: 'overlay', label: 'Sobreposição' },
                { id: 'darken', label: 'Escurecer' },
                { id: 'lighten', label: 'Clarear' },
                { id: 'color', label: 'Cor' },
                { id: 'luminosity', label: 'Luminosidade' },
                { id: 'saturation', label: 'Saturação' },
                { id: 'diference', label: 'Diferença' },
                { id: 'exclusion', label: 'Exclusão' },
              ]
            },
            {
              type: 'select',
              property: 'filter',
              default: '',
              label: 'Filtro: ',
              options: [
                { id: '', label: 'Normal' },
                { id: 'grayscale()', label: 'Preto&Branco' },
                { id: 'invert()', label: 'Inverter' },
                { id: 'sepia()', label: 'Sepia' },
                { id: 'saturate(10)', label: 'Saturação' },
                { id: 'blur(4px)', label: 'Blur' },
              ]
            },

          ]
        }
      ]
    },

    selectorManager: {
      appendTo: selectorManager.value,
      componentFirst: true,
    },

    layerManager: {
      appendTo: layerContent.value,
    },

    traitManager: {
      appendTo: configManager.value,
    },
  });

  editor.value.Panels.addPanel({
    id: "devices",
    el: ".devices",
    buttons: [
      {
        id: "set-device-desktop",
        command: {
          run(e) {
            e.setDevice("Desktop");
          },
          stop() { },
        },
        className: "devices-button",
        label: 'Desktop',
        attributes: { id: "desktop" },
        active: 1,
      },
      {
        id: "set-device-notebook",
        command: {
          run(e) {
            e.setDevice("Notebook");
          },
          stop() { },
        },
        className: "devices-button",
        label: "Notebook",
        attributes: { id: "notebook" },
      },
      {
        id: "set-device-tablet",
        command: {
          run(e) {
            e.setDevice("Tablet");
          },
          stop() { },
        },
        className: "devices-button",
        label: 'Tablet',
        attributes: { id: "tablet" },
      },
      {
        id: "set-device-mobile",
        command: {
          run(e) {
            e.setDevice("Mobile portrait");
          },
          stop() { },
        },
        className: "devices-button",
        label: 'Celular',
        attributes: { id: "mobile" },
      },
    ],
  });

  editor.value.Panels.addPanel({
    id: 'tools',
    el: '.top-tools',
    buttons: [
      {
        id: "visibility",
        label: 'Linhas guias',
        command: "sw-visibility",
      },
      {
        label: 'Réguas',
        context: "toggle-rulers",
        command: "ruler-visibility",
        id: "ruler-visibility",
      },
      {
        id: "fullscreen",
        command: "core:fullscreen",
        label: 'Pre-vizualizar',
      },
    ]
  });

  editor.value.Panels.addPanel({
    id: 'comment',
    el: '.top-comment',
    buttons: [
      {
        id: "comment",
        label: 'Ver comentários',
        command: "",
      },

      {
        id: "comment-add",
        label: 'Adicionar novo comentário',
        command: "",
        attributes: { class: 'add-comment' }
      },
    ]
  });

  editor.value.Panels.addPanel({
    id: "teste",
    el: ".options-tool",
    buttons: [

      /*  {
          id: "fonts",
          command: "open-fonts",
          attributes: { title: "Instalar fontes" },
          label: '<i class="bi bi-file-earmark-font"></i>',
        },*/
      {
        id: "undo",
        command: "core:undo",
        label: '<i class="bi bi-arrow-counterclockwise"></i>',
        attributes: { id: 'undo-btn' }
      },
      {
        id: "redo",
        command: "core:redo",
        label: '<i class="bi bi-arrow-clockwise"></i>',
        attributes: { id: 'do-btn' }
      },
      {
        id: "save",
        command: function () {
          saveType.value = "direct";
          editor.value.store();
        },
        label: '<i class="bi bi-floppy2-fill"></i> Salvar',
        attributes: { id: 'save-btn' }
      },
      {
        id: "view",
        command: function () {
          window.open(`https://${domain.value}/${url.value}`, '_blank');
        },
        label: 'Vizualizar versão salva',
        attributes: { id: 'prev-btn' }
      },
      /* {
         id: "publish",
         label: 'Publicar',
         attributes: { id: 'publish-btn' }
       }*/
    ],
  });
  // editor.value.Blocks.getCategories().each((ctg) => ctg.set("open", false));

  editor.value.on("run:preview", () => {
    previewMode.value = true;
    editor.value.stopCommand("sw-visibility");
  });

  editor.value.on("stop:preview", () => {
    previewMode.value = false;
    editor.value.runCommand("sw-visibility");
  });

  editor.value.on('component:selected', function (component) {
    const styleManager = editor.value.StyleManager;

    if (component.attributes.tagName == 'img') {
      styleManager.getSector('transformar').set({ open: true })
      styleManager.getSector('espaçamentos').set({ open: true })
    }
    else {
      styleManager.getSector('transformar').set({ open: false })
      styleManager.getSector('espaçamentos').set({ open: false })
    }
    if (component.attributes.type == 'text') {
      styleManager.getSector('typography').set({ open: true })
    }
    else {
      styleManager.getSector('typography').set({ open: false })
    }
  });

  editor.value.on("load", () => {
    const bm = editor.value.Blocks;
    const all = bm.getAll();
    const filter2 = all.filter((block) => block.get("fromPlugin") === 'blocks' || block.get("label") != 'Custom Code' && block.get("apper") != 'out' && block.get("label") != 'Formulário Criação Cubo' && block.get("label") != 'Formulário Atualização Cubo' && block.get("label") != 'Formulário Cubo (V2)');
    const filter3 = all.filter((block) => block.get("fromPlugin") === 'layout');
    const filter4 = all.filter((block) => block.get("fromPlugin") === 'extra' || block.get("label") === 'Custom Code' || block.get("label") === 'Typed')
    const filter5 = all.filter((block) => block.get("fromPlugin") === 'title');
    const filter6 = all.filter((block) => block.get("fromPlugin") === 'basic');
    const filter7 = all.filter((block) => block.get("fromPlugin") === 'button');
    const filter8 = all.filter((block) => block.get("fromPlugin") === 'image');
    const filter9 = all.filter((block) => block.get("fromPlugin") === 'video');
    const filter10 = all.filter((block) => block.get("fromPlugin") === 'slide');
    const filter11 = all.filter((block) => block.get("fromPlugin") === 'text');


    bm.getCategories().each(ctg => ctg.set('open', false))

    const newBlocksEl = bm.render(filter2, { external: true });
    document.getElementById('blocks-preset').appendChild(newBlocksEl);

    const newBlocksEl2 = bm.render(filter3, { external: true });
    document.getElementById('blocks-layout').appendChild(newBlocksEl2);

    const newBlocksEl3 = bm.render(filter4, { external: true });
    document.getElementById('blocks-extra').appendChild(newBlocksEl3);

    const newBlocksEl5 = bm.render(filter5, { external: true });
    document.getElementById('blocks-titles').appendChild(newBlocksEl5);

    const newBlocksEl6 = bm.render(filter6, { external: true });
    document.getElementById('blocks-form').appendChild(newBlocksEl6);

    const newBlocksEl7 = bm.render(filter7, { external: true });
    document.getElementById('blocks-button').appendChild(newBlocksEl7);

    const newBlocksEl8 = bm.render(filter8, { external: true });
    document.getElementById('blocks-image').appendChild(newBlocksEl8);

    const newBlocksEl9 = bm.render(filter9, { external: true });
    document.getElementById('blocks-video').appendChild(newBlocksEl9);

    const newBlocksEl10 = bm.render(filter10, { external: true });
    document.getElementById('blocks-slide').appendChild(newBlocksEl10);

    const newBlocksEl11 = bm.render(filter11, { external: true });
    document.getElementById('blocks-text').appendChild(newBlocksEl11);

    let styleManager = editor.value.StyleManager;
    let fontProperty = styleManager.getProperty("typography", "font-family");

    fontProperty.addOption({
      value: "",
      name: "Selecionar",
    });
    fontProperty.addOption({
      value: "'Acme', sans-serif",
      name: "Acme",
      style: 'font-family: Acme, sans-serif '
    });
    fontProperty.addOption({
      value: "'Alegreya Sans', sans-serif",
      name: "Alegreya Sans",
      style: 'font-family: Alegreya Sans, sans-serif '
    });
    fontProperty.addOption({
      value: "'Alegreya', serif",
      name: "Alegreya",
      style: 'font-family: Alegreya, serif '
    });
    fontProperty.addOption({
      value: "'Alex Brush', cursive",
      name: "Alex Brush",
      style: 'font-family: Alex Brush, cursive '
    });
    fontProperty.addOption({
      value: "'Anton', sans-serif",
      name: "Anton",
      style: 'font-family: Anton, sans-serif '
    });
    fontProperty.addOption({
      value: "'Archivo', sans-serif",
      name: "Archivo",
      style: 'font-family: Archivo, san-serif '
    });
    fontProperty.addOption({
      value: "'Arimo', sans-serif",
      name: "Arimo",
      style: 'font-family: Arimo, sans-serif '
    });
    fontProperty.addOption({
      value: "'Arvo', serif",
      name: "Arvo",
      style: 'font-family: Arvo, serif '
    });
    fontProperty.addOption({
      value: "'Asap Condensed', sans-serif",
      name: "Asap Condensed",
      style: 'font-family: Asap Condensed, sans-serif '
    });
    fontProperty.addOption({
      value: "'Bitter', serif",
      name: "Bitter",
      style: 'font-family: Bitter, serif '
    });
    fontProperty.addOption({
      value: "'Bowlby One SC', cursive",
      name: "Bowlby One SC",
      style: 'font-family: Bowlby One SC, cursive '
    });

    fontProperty.addOption({
      value: "'Cairo', sans-serif",
      name: "Cairo",
      style: 'font-family: Cairo, sans-serif '
    });
    fontProperty.addOption({
      value: "'Crete Round', serif",
      name: "Crete Round",
      style: 'font-family: Crete Round, sans-serif '
    });
    fontProperty.addOption({
      value: "'Cuprum', sans-serif",
      name: "Cuprum",
      style: 'font-family: Cuprum, sans-serif '
    });
    fontProperty.addOption({
      value: "'Dancing Script', cursive",
      name: "Dancing Script",
      style: 'font-family: Dancing Script, cursive '
    });
    fontProperty.addOption({
      value: "'Dosis', sans-serif",
      name: "Dosis",
      style: 'font-family: Dosis, sans-serif '
    });
    fontProperty.addOption({
      value: "'Droid Sans', sans-serif",
      name: "Droid Sans",
      style: 'font-family: Droid Sans, sans-serif '
    });
    fontProperty.addOption({
      value: "'Droid Serif', serif",
      name: "Droid Serif",
      style: 'font-family: Droid Serif, serif '
    });
    fontProperty.addOption({
      value: "'EB Garamond', serif",
      name: "EB Garamond",
      style: 'font-family: EB Garamond, serif '
    });
    fontProperty.addOption({
      value: "'Exo', sans-serif",
      name: "Exo",
      style: 'font-family: Exo, sans-serif '
    });
    fontProperty.addOption({
      value: "'Faustina', serif",
      name: "Faustina",
      style: 'font-family: Faustina, serif '
    });
    fontProperty.addOption({
      value: "'Fjalla One', sans-serif",
      name: "Fjalla One",
      style: 'font-family: Fjalla One, sans-serif '
    });
    fontProperty.addOption({
      value: "'Francois One', sans-serif",
      name: "Francois One",
      style: 'font-family: Francois One, sans-serif '
    });
    fontProperty.addOption({
      value: "'Fredoka', sans-serif",
      name: "Fredoka",
      style: 'font-family: Fredoka, sans-serif '
    });
    fontProperty.addOption({
      value: "'Great Vibes', cursive",
      name: "Great Vibes",
      style: 'font-family: Great Vibes, cursive '
    });
    fontProperty.addOption({
      value: "'Hind', sans-serif",
      name: "Hind",
      style: 'font-family: Hind, sans-serif '
    });
    fontProperty.addOption({
      value: "'Inconsolata', monospace",
      name: "Inconsolata",
      style: 'font-family: Inconsolata, monospace '
    });
    fontProperty.addOption({
      value: "'Indie Flower', cursive",
      name: "Indie Flower",
      style: 'font-family: Indie Flower, cursive '
    });
    fontProperty.addOption({
      value: "'Inter', sans-serif",
      name: "Inter",
      style: 'font-family: Inter, sans-serif '
    });
    fontProperty.addOption({
      value: "'Josefin Sans', sans-serif",
      name: "Josefin Sans",
      style: 'font-family: Josefin Sans, sans-serif '
    });
    fontProperty.addOption({
      value: "'Julee', cursive",
      name: "Julee",
      style: 'font-family: Julee, cursive '
    });
    fontProperty.addOption({
      value: "'Lato', sans-serif",
      name: "Lato",
      style: 'font-family: Lato, sans-serif '
    });
    fontProperty.addOption({
      value: "'Libre Franklin', sans-serif",
      name: "Libre Franklin",
      style: 'font-family: Libre Franklin, sans-serif '
    });
    fontProperty.addOption({
      value: "'Lora', serif",
      name: "Lora",
      style: 'font-family: Lora, serif '
    });
    fontProperty.addOption({
      value: "'Mada', sans-serif",
      name: "Mada",
      style: 'font-family: Mada, sans-serif '
    });
    fontProperty.addOption({
      value: "'Manuale', serif",
      name: "Manuale",
      style: 'font-family: Manuale, serif '
    });
    fontProperty.addOption({
      value: "'Maven Pro', sans-serif",
      name: "Maven Pro",
      style: 'font-family: Maven Pro, sans-serif '
    });
    fontProperty.addOption({
      value: "'Merriweather', serif",
      name: "Merriweather",
      style: 'font-family: Merriweather, serif '
    });
    fontProperty.addOption({
      value: "'Montserrat', sans-serif",
      name: "Montserrat",
      style: 'font-family: Montserrat, sans-serif '
    });
    fontProperty.addOption({
      value: "'Muli', sans-serif",
      name: "Muli",
      style: 'font-family: Muli, sans-serif '
    });
    fontProperty.addOption({
      value: "'Noto Sans', sans-serif",
      name: "Noto Sans",
      style: 'font-family: Noto Sans, sans-serif '
    });
    fontProperty.addOption({
      value: "'Noto Serif', serif",
      name: "Noto Serif",
      style: 'font-family: Noto Serif, serif '
    });
    fontProperty.addOption({
      value: "'Nunito', sans-serif",
      name: "Nunito",
      style: 'font-family: Nunito, sans-serif '
    });
    fontProperty.addOption({
      value: "'Open Sans Condensed', sans-serif",
      name: "Open Sans Condensed",
      style: 'font-family: Open Sans Condensed, sans-serif '
    });
    fontProperty.addOption({
      value: "'Open Sans', sans-serif",
      name: "Open Sans",
      style: 'font-family: Open Sans, sans-serif '
    });
    fontProperty.addOption({
      value: "'Oswald', sans-serif",
      name: "Oswald",
      style: 'font-family: Oswald, sans-serif '
    });
    fontProperty.addOption({
      value: "'Oxygen', sans-serif",
      name: "Oxygen",
      style: 'font-family: Oxygen, sans-serif '
    });
    fontProperty.addOption({
      value: "'Passion One', cursive",
      name: "Passion One",
      style: 'font-family: Passion One, cursive '
    });
    fontProperty.addOption({
      value: "'Pathway Gothic One', sans-serif",
      name: "Pathway Gothic One",
      style: 'font-family: Pathway Gothic One, sans-serif '
    });
    fontProperty.addOption({
      value: "'Play', sans-serif",
      name: "Play",
      style: 'font-family: Play, sans-serif '
    });
    fontProperty.addOption({
      value: "'Playfair Display', serif",
      name: "Playfair Display",
      style: 'font-family: Playfair Display, serif '
    });
    fontProperty.addOption({
      value: "'Poppins', sans-serif",
      name: "Poppins",
      style: 'font-family: Poppins, sans-serif '
    });
    fontProperty.addOption({
      value: "'PT Sans', sans-serif",
      name: "PT Sans",
      style: 'font-family: PT Sans, sans-serif '
    });
    fontProperty.addOption({
      value: "'PT Serif', serif",
      name: "PT Serif",
      style: 'font-family: PT Serif, serif '
    });
    fontProperty.addOption({
      value: "'Raleway', sans-serif",
      name: "Raleway",
      style: 'font-family: Raleway, sans-serif '
    });
    fontProperty.addOption({
      value: "'Red Hat Display', sans-serif",
      name: "Red Hat Display",
      style: 'font-family: Red Hat Display, sans-serif '
    });
    fontProperty.addOption({
      value: "'Roboto', sans-serif",
      name: "Roboto",
      style: 'font-family: Roboto, sans-serif '
    });
    fontProperty.addOption({
      value: "'Ropa Sans', sans-serif",
      name: "Ropa Sans",
      style: 'font-family: Ropa Sans, sans-serif '
    });
    fontProperty.addOption({
      value: "'Saira', sans-serif",
      name: "Saira",
      style: 'font-family: Saira, sans-serif '
    });
    fontProperty.addOption({
      value: "'Sedgwick Ave', cursive",
      name: "Sedgwick Ave",
      style: 'font-family: Sedgwick Ave, cursive '
    });
    fontProperty.addOption({
      value: "'Slabo 27px', serif",
      name: "Slabo 27px",
      style: 'font-family: Slabo 27px, serif '
    });
    fontProperty.addOption({
      value: "'Source Code Pro', monospace",
      name: "Source Code Pro",
      style: 'font-family: Source Code Pro, monospace '
    });
    fontProperty.addOption({
      value: "'Spectral', serif",
      name: "Spectral",
      style: 'font-family: Spectral, serif '
    });
    fontProperty.addOption({
      value: "'Titillium Web', sans-serif",
      name: "Titillium Web",
      style: 'font-family: Titillium Web, sans-serif '
    });
    fontProperty.addOption({
      value: "'Vollkorn', serif",
      name: "Vollkorn",
      style: 'font-family: Vollkorn, serif '
    });
    fontProperty.addOption({
      value: "'Zilla Slab', serif",
      name: "Zilla Slab",
      style: 'font-family: Zilla Slab, serif '
    });
    fontProperty.addOption({
      value: "'Zilla Slab Highlight', cursive",
      name: "Zilla Slab Highlight",
      style: 'font-family: Zilla Slab Highlight, cursive '
    });



  });

  editor.value.StorageManager.add("cubo", {
    async load() {
      saving.value = true;
      loaderText.value = "Carregando Página"
      const { data: landing } = await useFetch(
        `${process.env.VUE_APP_URL_API}/landings/${route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        },
        {
          onFetchError(ctx) {
            console.log(ctx.response.status);
            if (ctx.response.status === 401 || ctx.response.status === 404) {
              router.push({
                name: "Unauthorized",
              });
            }
          },
        }
      ).json();

      url.value = landing.value.url;
      domain.value = landing.value.domain.domain;
      saving.value = false;

      console.log(landing.value.styles)
      return {
        assets: JSON.parse(landing.value.assets),
        styles: JSON.parse(landing.value.styles) || landing.value.css,
        pages: [
          {
            frames: [
              {
                component: {
                  type: "wrapper",
                  stylable: [
                    "background",
                    "background-color",
                    "background-image",
                    "background-repeat",
                    "background-attachment",
                    "background-position",
                    "background-size",
                  ],
                  components: [JSON.parse(landing.value.components)],
                },
              },
            ],
          },
        ],
      };
    },

    async store(data) {
      loaderText.value = "Salvando Alterações";

      const css = editor.value.getCss();
      const head = `<style type="text/css">${css}</style>`;

      const payload = {
        assets: JSON.stringify(data.data.assets),
        components: JSON.stringify(
          data.data.pages[0].frames[0].component.components
        ),
        css: data.pagesHtml[0].css,
        html: data.pagesHtml[0].html,
        styles: JSON.stringify(data.data.styles),
        head,
      };

      saving.value = false;

      Swal.fire({
        text: "Página salva.",
        showConfirmButton: false,
        toast: true,
        position: "top-start",
        timer: "2000",
        timerProgressBar: true,
        footer: `<a href="https://${domain.value}/${url.value}" target="_BLANK">Visualizar Página</a>`,
      });

      await useFetch(
        `${process.env.VUE_APP_URL_API}/landings/${route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        }
      )
        .put(payload)
        .json();

      return;
    },
  });
});

const toggleMenu = (type, event) => {
  if (type === "blocks") {
    if (!event.target.checked) {
      blocksPreset.value.classList.remove("display-block");
      blockButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkBlock.value.checked = false;
      checkPanelBlock.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksPreset.value.classList.add("display-block");
    blockButton.value.classList.add("active");

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;


    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelBlocks") {
    if (!event.target.checked) {
      blocksPreset.value.classList.remove("display-block");
      blockButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      checkBlock.value.checked = false;
      checkPanelBlock.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;

    editor.value.refresh();
  }
  if (type === "titles") {
    if (!event.target.checked) {
      blocksTitles.value.classList.remove("display-block");
      titlesButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkTitles.value.checked = false;
      checkPanelTitle.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksTitles.value.classList.add("display-block");
    titlesButton.value.classList.add("active");

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelTitles") {
    if (!event.target.checked) {
      blocksTitles.value.classList.remove("display-block");
      titleButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      checkTitles.value.checked = false;
      checkPanelTitle.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;


    editor.value.refresh();
  }
  if (type === "text") {
    if (!event.target.checked) {
      blocksText.value.classList.remove("display-block");
      textButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkText.value.checked = false;
      checkPanelText.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksText.value.classList.add("display-block");
    textButton.value.classList.add("active");

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelText") {
    if (!event.target.checked) {
      blocksText.value.classList.remove("display-block");
      textButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      checkText.value.checked = false;
      checkPanelText.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;


    editor.value.refresh();
  }

  if (type === "button") {
    if (!event.target.checked) {
      blocksButton.value.classList.remove("display-block");
      buttonButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkButton.value.checked = false;
      checkPanelButton.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksButton.value.classList.add("display-block");
    buttonButton.value.classList.add("active");

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelButton") {
    if (!event.target.checked) {
      blocksButton.value.classList.remove("display-block");
      buttonButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      checkButton.value.checked = false;
      checkPanelButton.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;


    editor.value.refresh();
  }

  if (type === "image") {
    if (!event.target.checked) {
      blocksImage.value.classList.remove("display-block");
      imagesButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkImages.value.checked = false;
      checkPanelImage.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksImage.value.classList.add("display-block");
    imagesButton.value.classList.add("active");

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelImage") {
    if (!event.target.checked) {
      blocksImage.value.classList.remove("display-block");
      imagesButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      checkImages.value.checked = false;
      checkPanelImage.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;


    editor.value.refresh();
  }

  if (type === "video") {
    if (!event.target.checked) {
      blocksVideos.value.classList.remove("display-block");
      videosButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkVideos.value.checked = false;
      checkPanelVideos.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksVideos.value.classList.add("display-block");
    videosButton.value.classList.add("active");

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelVideo") {
    if (!event.target.checked) {
      blocksVideos.value.classList.remove("display-block");
      videosButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      checkVideos.value.checked = false;
      checkPanelVideos.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;


    editor.value.refresh();
  }

  if (type === "forms") {
    if (!event.target.checked) {
      blocksForms.value.classList.remove("display-block");
      formsButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkForms.value.checked = false;
      checkPanelForms.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksForms.value.classList.add("display-block");
    formsButton.value.classList.add("active");

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelForms") {
    if (!event.target.checked) {
      blocksForms.value.classList.remove("display-block");
      formsButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      checkForms.value.checked = false;
      checkPanelForms.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;


    editor.value.refresh();
  }

  if (type === "slide") {
    if (!event.target.checked) {
      blocksSlides.value.classList.remove("display-block");
      slidesButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkSlides.value.checked = false;
      checkPanelSlides.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksSlides.value.classList.add("display-block");
    slidesButton.value.classList.add("active");

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelSlides") {
    if (!event.target.checked) {
      blocksSlides.value.classList.remove("display-block");
      slidesButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      checkSlides.value.checked = false;
      checkPanelSlides.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;


    editor.value.refresh();
  }

  if (type === "layout") {
    if (!event.target.checked) {
      blocksLayout.value.classList.remove("display-block");
      layoutButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkLayout.value.checked = false;
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksLayout.value.classList.add("display-block");
    layoutButton.value.classList.add("active");


    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelLayout") {
    if (!event.target.checked) {
      blocksLayout.value.classList.remove("display-block");
      layerButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      checkLayout.value.checked = false;
      checkPanelLayout.value.checked = false
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;
    checkPanelLayout.value.checked = false

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    editor.value.refresh();
  }

  if (type === "extra") {
    if (!event.target.checked) {
      blocksExtra.value.classList.remove("display-block");
      extraButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.add("open-layer");
      layers.value.classList.remove("close-layer");
      checkExtra.value.checked = false;
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksExtra.value.classList.add("display-block");
    extraButton.value.classList.add("active");

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    layers.value.classList.remove("open-layer");
    layers.value.classList.add("close-layer");
    checklayer.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    editor.value.refresh();
  }

  if (type === "panelExtra") {
    if (!event.target.checked) {
      blocksExtra.value.classList.remove("display-block");
      extraButton.value.classList.remove("active");
      builder.value.classList.add("w-complete");
      layers.value.classList.remove("open-layer");
      layers.value.classList.add("close-layer");
      checkExtra.value.checked = false;
      checkPanelExtra.value.checked = false;
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");

    blocksExtra.value.classList.remove("display-block");
    extraButton.value.classList.remove("active");
    checkExtra.value.checked = false;
    checkPanelExtra.value.checked = false

    blocksLayout.value.classList.remove("display-block");
    layoutButton.value.classList.remove("active");
    checkLayout.value.checked = false;

    blocksPreset.value.classList.remove("display-block");
    blockButton.value.classList.remove("active");
    checkBlock.value.checked = false;

    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    checklayer.value.checked = false;

    blocksButton.value.classList.remove("display-block");
    buttonButton.value.classList.remove("active");
    checkButton.value.checked = false;

    blocksTitles.value.classList.remove("display-block");
    titlesButton.value.classList.remove("active");
    checkTitles.value.checked = false;

    blocksImage.value.classList.remove("display-block");
    imagesButton.value.classList.remove("active");
    checkImages.value.checked = false;

    blocksText.value.classList.remove("display-block");
    textButton.value.classList.remove("active");
    checkText.value.checked = false;

    blocksForms.value.classList.remove("display-block");
    formsButton.value.classList.remove("active");
    checkForms.value.checked = false;

    blocksSlides.value.classList.remove("display-block");
    slidesButton.value.classList.remove("active");
    checkSlides.value.checked = false;

    blocksVideos.value.classList.remove("display-block");
    videosButton.value.classList.remove("active");
    checkVideos.value.checked = false;

    editor.value.refresh();
  }

  if (type === "layers") {
    if (!event.target.checked) {
      layers.value.classList.remove("open-layer");
      layers.value.classList.add("close-layer");
      //layerButton.value.classList.remove("active");
      builder.value.classList.remove("w-complete");
      checklayer.value.checked = false;
      editor.value.refresh();
      return;
    }

    builder.value.classList.add("w-complete");
    layers.value.classList.add("open-layer");
    layers.value.classList.remove("close-layer");
    //layerButton.value.classList.add("active");

    editor.value.refresh();
    return;
  }

  if (type === "configs") {
    if (!event.target.checked) {
      editor.value.refresh();
      return;
    }

    configManager.value.classList.add("display-block");
    configButton.value.classList.add("active");

    styleManager.value.classList.remove("display-block");
    styleButton.value.classList.remove("active");

    checkStyle.value.checked = false;

    editor.value.refresh();
    return;
  }

  if (type === "styles") {
    if (!event.target.checked) {
      editor.value.refresh();
      return;
    }

    styleManager.value.classList.add("display-block");
    styleButton.value.classList.add("active");

    configManager.value.classList.remove("display-block");
    configButton.value.classList.remove("active");

    checkConfig.value.checked = false;

    editor.value.refresh();
    return;
  }

  if (type === "tools") {
    if (!event.target.checked) {
      toolsDiv.value.classList.remove('active')
      toolsBar.value.classList.remove('display-block')
      toolsButton.value.checked = false;
      return;
    }

    toolsDiv.value.classList.add('active')
    toolsBar.value.classList.add('display-block')

    commentDiv.value.classList.remove('active')
    commentBar.value.classList.remove('display-block')
    commentButton.value.checked = false;
    return;
  }

  if (type === "comment") {
    if (!event.target.checked) {
      commentDiv.value.classList.remove('active')
      commentBar.value.classList.remove('display-block')
      commentButton.value.checked = false;
      return;
    }

    commentDiv.value.classList.add('active')
    commentBar.value.classList.add('display-block')

    toolsDiv.value.classList.remove('active')
    toolsBar.value.classList.remove('display-block')
    toolsButton.value.checked = false;
    return;
  }


  window.onclick = function (event) {
    if (!event.target.matches('.btn-menu')) {
      commentDiv.value.classList.remove('active')
      commentBar.value.classList.remove('display-block')
      commentButton.value.checked = false;

      toolsDiv.value.classList.remove('active')
      toolsBar.value.classList.remove('display-block')
      toolsButton.value.checked = false;

    }
  }
}
</script>
